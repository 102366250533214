import React, { useEffect } from "react";
import { Link, Outlet } from "react-router-dom";
// import SideMenu from "../../components/SideMenu";

const SideBarScreen = (props) => {
  useEffect(() => {
    props.funcNav(false);
    props.funcFoot(false);
    return () => {
      props.funcFoot(true);
      props.funcNav(true);
    };
  });
  return (
    <div>
      <nav class="navbar navbar-expand-lg navbar-light bg-light d-flex ">
        <a class="navbar-brand" href="#">
          <img src="/assets/img/bharat-logo.svg" style={{ padding: 5 }} />
        </a>

        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <a href="" className="float-right">
          <i class="fa-solid fa-gear"></i>
        </a>
      </nav>
      <div className="row d-flex ">
        <div className="col-2 side-menu-container">
          <div className="accordion" id="accordionMenu">
            <div className="accordion-item side-menu-item-acc">
              <h2 className="accordion-header" id="headingOne">
                <Link style={{ textDecoration: "none" }} to={""}>
                  <button
                    className="accordion-button"
                    type="button"
                  //   data-bs-toggle="collapse"
                  //   data-bs-target="#collapseOne"
                  >
                    Dashboard
                  </button>
                </Link>
              </h2>
              <div
                id="collapseOne"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionMenu"
              >
                <div className="accordion-body">
                  <ul>
                    <li className="side-menu-item">Admin</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="accordion-item side-menu-item-acc">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsenine"
                >
                  Bookings
                </button>
              </h2>
              <div
                id="collapsenine"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionMenu"
              >
                <div className="accordion-body">
                  <ul>
                    <li className="side-menu-item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to={"booking-list"}
                      >
                        Booking List
                      </Link>
                    </li>

                  </ul>
                </div>
              </div>
            </div>

            <div className="accordion-item side-menu-item-acc">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                >
                  Hotel
                </button>
              </h2>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionMenu"
              >
                <div className="accordion-body">
                  <ul>
                    <li className="side-menu-item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to={"hotel-listing"}
                      >
                        Hotel List
                      </Link>
                    </li>
                    <li className="side-menu-item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to={"hotel-city"}
                      >
                        Hotel City
                      </Link>
                    </li>

                    <li className="side-menu-item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to={"room-facility"}
                      >
                        Room Facility
                      </Link>
                    </li>

                    {/* <li className="side-menu-item">
                      <a href="">Hotel Summary</a>
                    </li>
                    <li className="side-menu-item">
                      <a href="">Hotel Homepage</a>
                    </li> */}
                    <li className="side-menu-item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to={"hotel-booking-list"}
                      >
                        Hotel Booking
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="accordion-item side-menu-item-acc">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThreeaa"
                >
                  New Wedding
                </button>
              </h2>
              <div
                id="collapseThreeaa"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionMenu"
              >
                <div className="accordion-body">
                  <ul>
                    <li className="side-menu-item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to={"new-wedding-banner"}
                      >
                        New Wedding Banner
                      </Link>
                    </li>


                    <li className="side-menu-item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to={"new-wedding-inspiration-list"}
                      >
                        Wedding Inspiration
                      </Link>
                    </li>

                    <li className="side-menu-item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to={"new-real-wedding-list"}
                      >
                        The Real Wedding
                      </Link>
                    </li>

                    <li className="side-menu-item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to={"new-wedding-venu-list"}
                      >
                        Wedding Venues

                      </Link>
                    </li>
                    <li className="side-menu-item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to={"new-wedding-testimonial"}
                      >
                        Wedding Testimonial

                      </Link>
                    </li>
                    <li className="side-menu-item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to={"new-wedding-highlights"}
                      >
                        Wedding Highlights

                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="accordion-item side-menu-item-acc">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThreeaaw"
                >
                  New Mice
                </button>
              </h2>
              <div
                id="collapseThreeaaw"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionMenu"
              >
                <div className="accordion-body">
                  <ul>
                    <li className="side-menu-item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to={"new-mice-banner"}
                      >
                        New Mice Banner
                      </Link>
                    </li>


                    <li className="side-menu-item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to={"new-mice-inspiration-list"}
                      >
                        Mice Inspiration
                      </Link>
                    </li>

                    <li className="side-menu-item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to={"new-real-mice-list"}
                      >
                        Mice Destination
                      </Link>
                    </li>

                    <li className="side-menu-item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to={"new-mice-venu-list"}
                      >
                        Mice Venues

                      </Link>
                    </li>
                    <li className="side-menu-item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to={"new-mice-testimonial"}
                      >
                        Mice Testimonial

                      </Link>
                    </li>
                    <li className="side-menu-item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to={"new-mice-highlights"}
                      >
                        Mice Highlights

                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="accordion-item side-menu-item-acc">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                >
                  Dining
                </button>
              </h2>
              <div
                id="collapseFour"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionMenu"
              >
                <div className="accordion-body">
                  <ul>
                    <li className="side-menu-item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to={"dining-list"}
                      >
                        Dining List
                      </Link>
                    </li>
                    <li className="side-menu-item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to={"dining-city"}
                      >
                        Dining City
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="accordion-item side-menu-item-acc">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                >
                  Activity
                </button>
              </h2>
              <div
                id="collapseFive"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionMenu"
              >
                <div className="accordion-body">
                  <ul>
                    <li className="side-menu-item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to={"activity-list"}
                      >
                        Activity List
                      </Link>
                    </li>
                    <li className="side-menu-item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to={"activity-city"}
                      >
                        Activity City
                      </Link>
                    </li>
                    <li className="side-menu-item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to={"show-activity-ticket"}
                      >
                        Activity Ticket
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="accordion-item side-menu-item-acc">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSix"
                >
                  Vehicles
                </button>
              </h2>
              <div
                id="collapseSix"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionMenu"
              >
                <div className="accordion-body">
                  <ul>
                    <li className="side-menu-item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to={"vehicles-list"}
                      >
                        Vehicles List
                      </Link>
                    </li>
                    <li className="side-menu-item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to={"vehicles-city"}
                      >
                        Vehicles City
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="accordion-item side-menu-item-acc">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSeven"
                >
                  Package
                </button>
              </h2>
              <div
                id="collapseSeven"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionMenu"
              >
                <div className="accordion-body">
                  <ul>
                    <li className="side-menu-item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to={"package-list"}
                      >
                        Package List
                      </Link>
                    </li>
                    <li className="side-menu-item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to={"package-city"}
                      >
                        Package City
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>


            <div className="accordion-item side-menu-item-acc">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                >
                  Wedding
                </button>
              </h2>
              <div
                id="collapseThree"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionMenu"
              >
                <div className="accordion-body">
                  <ul>
                    <li className="side-menu-item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to={"wedding-details"}
                      >
                        Wedding Details
                      </Link>
                    </li>
                    <li className="side-menu-item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to={"wedding-list"}
                      >
                        Wedding
                      </Link>
                    </li>

                    <li className="side-menu-item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to={"wedding-banner"}
                      >
                        Wedding Banner
                      </Link>
                    </li>

                    <li className="side-menu-item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to={"wedding-real-venue-list"}
                      >
                        Wedding Real and Venu
                      </Link>
                    </li>

                    <li className="side-menu-item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to={"wedding-inspiration-list"}
                      >
                        Wedding Inspiration
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>


            <div className="accordion-item side-menu-item-acc">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseeleven"
                >
                  MICE
                </button>
              </h2>
              <div
                id="collapseeleven"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionMenu"
              >
                <div className="accordion-body">
                  <ul>
                    <li className="side-menu-item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to={"mice-details"}
                      >
                        Mice Details
                      </Link>
                    </li>
                    <li className="side-menu-item">
                      <Link style={{ textDecoration: "none" }} to={"mice-list"}>
                        Mice
                      </Link>
                    </li>

                    <li className="side-menu-item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to={"mice-banner"}
                      >
                        Mice Banner
                      </Link>
                    </li>

                    <li className="side-menu-item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to={"mice-concept-list"}
                      >
                        Mice Concept
                      </Link>
                    </li>

                    <li className="side-menu-item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to={"mice-venue-list"}
                      >
                        Mice Venue
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="accordion-item side-menu-item-acc">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEight"
                >
                  Blogs
                </button>
              </h2>
              <div
                id="collapseEight"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionMenu"
              >
                <div className="accordion-body">
                  <ul>

                    <li className="side-menu-item">
                      <Link style={{ textDecoration: "none" }} to={"blog-banner"}>
                        Blog Banner
                      </Link>
                    </li>

                    <li className="side-menu-item">
                      <Link style={{ textDecoration: "none" }} to={"category"}>
                        Category List
                      </Link>
                    </li>
                    <li className="side-menu-item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to={"sub-category"}
                      >
                        Sub Category List
                      </Link>
                    </li>
                    <li className="side-menu-item">
                      <Link style={{ textDecoration: "none" }} to={"blogs"}>
                        Blogs List
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="accordion-item side-menu-item-acc">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEvent"
                >
                  Events
                </button>
              </h2>
              <div
                id="collapseEvent"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionMenu"
              >
                <div className="accordion-body">
                  <ul>
                    <li className="side-menu-item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to={"event-banner"}
                      >
                        Event Banner
                      </Link>
                    </li>
                    <li className="side-menu-item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to={"event-list"}
                      >
                        Events List
                      </Link>
                    </li>

                    <li className="side-menu-item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to={"event-blog-list"}
                      >
                        Events Blog
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>



            <div className="accordion-item side-menu-item-acc">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsetweleve"
                >
                  Partner With Us
                </button>
              </h2>
              <div
                id="collapsetweleve"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionMenu"
              >
                <div className="accordion-body">
                  <ul>
                    <li className="side-menu-item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to={"why-choose-us-list"}
                      >
                        Why Choose Us
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="accordion-item side-menu-item-acc">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsethirteen"
                >
                  Home Page
                </button>
              </h2>
              <div
                id="collapsethirteen"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionMenu"
              >
                <div className="accordion-body">
                  <ul>
                    <li className="side-menu-item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to={"home-banner-list"}
                      >
                        Home Banner
                      </Link>
                    </li>
                    <li className="side-menu-item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to={"section-second-list"}
                      >
                        Section Second
                      </Link>
                    </li>
                    <li className="side-menu-item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to={"recomended-list"}
                      >
                        Recomended
                      </Link>
                    </li>
                    <li className="side-menu-item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to={"section-fourth-list"}
                      >
                        Section Fourth
                      </Link>
                    </li>
                    <li className="side-menu-item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to={"join-network-list"}
                      >
                        Join Network
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="side-menu-item">
            <Link style={{ textDecoration: "none" }} to={"product-blog-list"}>
              <span>Product Blog</span>
            </Link>
          </div>
          <div className="side-menu-item">
            <Link style={{ textDecoration: "none" }} to={"faq-list"}>
              <span>FAQ's</span>
            </Link>
          </div>
          <div className="side-menu-item">
            <Link style={{ textDecoration: "none" }} to={"testimonial-list"}>
              <span>Testimonial</span>
            </Link>
          </div>
          <div className="side-menu-item">
            <Link style={{ textDecoration: "none" }} to={"enquiry-list"}>
              <span>Customer Enquiry</span>
            </Link>
          </div>
          <div className="side-menu-item">
            <Link style={{ textDecoration: "none" }} to={"pages"}>
              <span>Pages</span>
            </Link>
          </div>
          <div className="side-menu-item">
            <Link style={{ textDecoration: "none" }} to={"subscribe-list"}>
              <span>Subscribe</span>
            </Link>
          </div>
          <div className="side-menu-item">
            <Link style={{ textDecoration: "none" }} to={"social-link-detail"}>
              <span>Social Link</span>
            </Link>
          </div>
          <div className="side-menu-item">
            <a href="">
              <span>Logout</span>
            </a>
          </div>
        </div>
        <div className="col-9 dash-content-section mt-4 mx-auto">
          <Outlet></Outlet>
        </div>
      </div>
    </div>
  );
};

export default SideBarScreen;

import React, { useState, useEffect } from "react";
import { END_POINT, METHODS, BASE_URL_IMAGE } from "../../../Helper/helper";
import {
  FileUpload,
  ImageUpload,
  VideoUpload,
  fetchData,
} from "../../../Helper/ApiCall";
import Select from "react-select";
import { Button, Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromRaw,
  convertFromHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
const AddBlog = () => {
  const navigate = useNavigate();
  let location = useLocation();
  const cityid = location?.state?.id;

  const [formData, setformData] = useState({
    category_id: "",
    sub_category_id: undefined,
    title: "",
    description: "",
    article: "",
    author: "",

    metatitle: "",
    metadescription: "",
    metakeywords: "",
    image: "",
  });
  const handleChange = (e) => {
    setformData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const [image, setimage] = useState("");
  const [catData, setcatData] = useState([]);
  const [subcatData, setsubcatData] = useState([]);

  useEffect(() => {
    categoryHandler();
    if (cityid != undefined) {
      cityHandler();
    }
  }, []);
  const categoryHandler = async () => {
    try {
      const result = await fetchData(END_POINT.GetCategoryDataBlog);
      if (result.success == true) {
        setcatData(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const subcategoryHandler = async (id) => {
    try {
      const result = await fetchData(END_POINT.GetSubCategoryByCatId + id);
      console.log("hello");
      console.log(id)
      if (result.success == true) {
        setsubcatData(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const cityHandler = async () => {
    try {
      const result = await fetchData(END_POINT.GetDetailDataBlog + cityid);

      if (result.success == true) {
        setformData({
          category_id: result.data.category_id,
          sub_category_id: result.data.sub_category_id,
          title: result.data.title,
          description: result.data.description,
          article: result.data.article,
          author: result.data.author,

          metatitle: result.data.metatitle,
          metadescription: result.data.metadescription,
          metakeywords: result.data.metakeywords,
          image: result.data.image,
        });
        subcategoryHandler(result.data.category_id);
        setimage(result.data.image);
        seteditorState(
          EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(result.data.article)
            )
          )
        );
        // seteditorState(result.data.article);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleSubmit = async (uploadedImagebanner) => {
    var raw = JSON.stringify({
      category_id: formData.category_id,
      sub_category_id: formData.sub_category_id,
      title: formData.title,
      description: formData.description,
      article: formData.article,
      author: formData.author,
      metatitle: formData.metatitle,
      metadescription: formData.metadescription,
      metakeywords: formData.metakeywords,
      image: uploadedImagebanner,
    });
    console.log("raw", raw);
    try {
      const finalEndpoint =
        cityid != undefined
          ? END_POINT.UpdateBlog + cityid
          : END_POINT.UploadBlog;
      const result = await fetchData(finalEndpoint, METHODS.POST, raw);
      console.log("API response:", result);
      if (result.success == true) {
        Swal.fire({
          title: "Confirmed",
          text: result.message,
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("../blogs");
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const AllFileUpload = async () => {
    Promise.all([await ImageUpload(image)])
      .then((values) => {
        const uploadedImagebanner = values[0].image;
        handleSubmit(uploadedImagebanner);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const AllFileUpdate = async () => {
    Promise.all([await ImageUpload(image)])
      .then((values) => {
        const uploadedImagedininglist =
          values[0].image == undefined ? formData.image : values[0].image;

        handleSubmit(uploadedImagedininglist);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const [editorState, seteditorState] = useState(EditorState.createEmpty());
  const onEditorStateChange = (editorState) => {
    seteditorState(editorState);
    setformData({
      ...formData,
      article: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    });
  };

  return (
    <div
    //  onSubmit={AllFileUpload}
    >
      <div>
        <h3 className="mb-4">
          <b>Add Blog</b>
        </h3>
      </div>
      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Select Category:</span> <br />
            <Select
              value={catData.filter((item) => item._id == formData.category_id)}
              options={catData}
              className="input-fields-style"
              // onChange={hotelTypeHandleChange}
              placeholder="Select an option"
              isSearchable
              getOptionLabel={(option) => option.categoryname}
              getOptionValue={(option) => `${option}`}
              isOptionSelected={(option) =>
                formData.category_id === option._id ? true : false
              }
              onChange={(e) => {
                subcategoryHandler(e._id);
                setformData({
                  ...formData,
                  category_id: e._id,
                });
              }}
            />
          </div>
        </div>
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Select Sub Category:</span> <br />
            <Select
              value={subcatData.filter(
                (item) => item._id == formData.sub_category_id
              )}
              options={subcatData}
              className="input-fields-style"
              // onChange={hotelTypeHandleChange}
              placeholder="Select an option"
              isSearchable
              getOptionLabel={(option) => option.sub_category_name}
              getOptionValue={(option) => `${option}`}
              isOptionSelected={(option) =>
                formData.sub_category_id === option._id ? true : false
              }
              onChange={(e) => {
                setformData({
                  ...formData,
                  sub_category_id: e._id,
                });
              }}
            />
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Title:</span> <br />
            <input
              type="text"
              id="title"
              name="title"
              className="input-fields-style"
              value={formData.title}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Desc:</span> <br />
            <input
              type="text"
              id="description"
              name="description"
              className="input-fields-style"
              value={formData.description}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>
      <div className="col-lg-10 md-5 sm-12">
        <div>
          <span className="label-style">Article:</span> <br />
          {/* <textarea
            id="description"
            name="description"
            value={formData.description}
            onChange={(e) => handleChange(e)}
            rows={5}
            style={{ width: "100%" }}
            placeholder="Enter Here..."
          /> */}
          <Editor
            editorState={editorState}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            onEditorStateChange={onEditorStateChange}
          />
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Author:</span> <br />
            <input
              type="text"
              id="author"
              name="author"
              className="input-fields-style"
              value={formData.author}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Meta Title:</span> <br />
            <input
              type="text"
              id="metatitle"
              name="metatitle"
              className="input-fields-style"
              value={formData.metatitle}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Meta Desc:</span> <br />
            <input
              type="text"
              id="metadescription"
              name="metadescription"
              className="input-fields-style"
              value={formData.metadescription}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Meta Keywords:</span> <br />
            <input
              type="text"
              id="metakeywords"
              name="metakeywords"
              className="input-fields-style"
              value={formData.metakeywords}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-lg-6 md-6 sm-12">
          <div>
            <span className="label-style">Image *</span> <br />
            <input
              type="file"
              id="image"
              name="image"
              accept="image/*"
              onChange={(e) => {
                setimage(e.target.files[0]);
              }}
            />
            {formData.image != "" && (
              <a
                href={BASE_URL_IMAGE + formData.image}
                target="_blank"
                rel="noopener noreferrer"
                className="mt-5"
              >
                view image
              </a>
            )}
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-start">
        <Button
          id="submit"
          type="button"
          className="btn btn-primary m-2"
          // name="submit"
          onClick={() => {
            if (cityid != undefined) {
              if (image != "") {
                AllFileUpdate();
              } else {
                handleSubmit(
                  formData.video,
                  formData.banner_image,
                  formData.image
                );
              }
            } else {
              AllFileUpload();
            }
          }}
        >
          Submit
        </Button>

        <Button
          className="btn btn-danger m-2"
          type="cancel"
          onClick={() => {
            navigate("../category");
          }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default AddBlog;

import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Navigation, Autoplay } from "swiper/modules";
import { SwiperNavButtons } from "../../components/SwiperNavButton";
import {
    useLocation,
    Link,
    useParams,
    BrowserRouter as Router,
    Route,
    Switch,
} from "react-router-dom";

import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { SwiperNavYellow } from "../../components/SwiperNavYellow";
import { Button, Container, Row } from "react-bootstrap";
import { fetchData } from "../../Helper/ApiCall";
import {
    END_POINT,
    BASE_URL_IMAGE,
    METHODS,
    NumberSeprator,
    numberToEnglish,
    TabBarLabels,
} from "../../Helper/helper";
import Subscribe from "../../components/Subscribe";



export const NewCityBlogsScreen = () => {

    const location = useLocation();

    const NameDataid = location.state?.id;

    const { category, name } = useParams();
    const [data, setdata] = useState([]);

    useEffect(() => {
        handler();
    }, []);
    const handler = async () => {
        try {
            const raw = JSON.stringify({
                category: category,
                name: name,
            });
            const result = await fetchData(
                END_POINT.GetWebBlogBySubCatNameData,
                METHODS.POST,
                raw
            );
            console.log("helloooo");
            console.log(raw);
            if (result.success == true) {

                setdata(result.data);
            }
        } catch (err) {
            console.log(err);
        }
    };
    return (
        <div>
            {/* Navbar */}
            {/* <Header /> */}

            {/* Hero Banner */}
            <div class="mice-outer">
                <Swiper
                    slidesPerView={1}
                    spaceBetween={0}
                    loop={true}
                    //   modules={[Autoplay]}
                    //   autoplay={{
                    //     delay: 3500,
                    //     disableOnInteraction: true,
                    //   }}
                    //   breakpoints={{
                    //     "@0.00": {
                    //       slidesPerView: 2,
                    //       spaceBetween: 5,
                    //     },
                    //     "@0.75": {
                    //       slidesPerView: 3,
                    //       spaceBetween: 10,
                    //     },
                    //     "@1.00": {
                    //       slidesPerView: 4,
                    //       spaceBetween: 30,
                    //     },
                    //     "@1.50": {
                    //       slidesPerView: 5,
                    //       spaceBetween: 40,
                    //     },
                    //   }}

                    className="micecarousel"
                >
                    <SwiperSlide>
                        <div className="city-blogs-banner"
                            style={{
                                backgroundImage: `url(${BASE_URL_IMAGE + data?.[0]?.banner_image})`,
                            }}

                        >
                            <div className="row">
                                <div className="col-9 mice-banner-text d-flex flex-column align-items-center justify-content-center mx-auto">
                                    <h1>{name == undefined ? category : name} </h1>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
            {/* Welcome Section */}

            <div className="container welcome-section">
                {/* <img
          src="/assets/img/mid-left-leaf.svg"
          className="img-fluid mid-left-leaf"
        /> */}
                {/* <img
          src={name === undefined ? BASE_URL_IMAGE + category : BASE_URL_IMAGE + image}
          className="img-fluid mid-left-leaf"
        /> */}

                <h2>{name == undefined ? category : name} blogs</h2>
                <p className="events-intro">
                    Our travel blogs will transport you to far-off lands, where you can
                    indulge in exotic flavors, revel in festivals, and let your mind
                    wander.
                </p>
                <div className="row mx-0 mb-2" >
                    {data.length > 0 &&
                        data[0].blogs.length > 0 &&
                        data[0].blogs.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className="col-lg-4 col-md-6 col-sm-12 venue-cards"
                                    style={{ paddingLeft: "15px", paddingRight: "15px" }}
                                >
                                    <Link to={"/blog-detail/" + item._id}>
                                        <img
                                            src={BASE_URL_IMAGE + item.image}
                                            className="img-fluid"
                                            style={{
                                                height: "260px",
                                                width: "100%",
                                                objectFit: "cover",
                                                borderRadius: "8px",
                                            }}
                                            alt={item.title || "Blog image"}
                                        />
                                        <div className="venue-card-text mt-2">
                                            <h4>{item.title}</h4>
                                            <p>
                                                {item.description.length > 100
                                                    ? `${item.description.substring(0, 100)}...`
                                                    : item.description}
                                            </p>
                                        </div>
                                    </Link>
                                </div>
                            );
                        })}
                </div>



            </div>

            {/* CTA Section */}

            <Subscribe
                title={`Stay Connected: Get Exclusive Updates with Our Newsletter`}
            />
            {/* Footer */}

            {/* <Footer /> */}
        </div>
    );
};

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BASE_URL_IMAGE, END_POINT } from "../../../Helper/helper";
import { fetchData } from "../../../Helper/ApiCall";
import Swal from "sweetalert2";

import "../../dashscreen/DashCss.css";

import { useSelector, useDispatch } from "react-redux";
import TableHead from "../../components/TableHead";

const NewMiceTestimonial = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        handler();
    }, []);

    const handler = async () => {
        try {
            const result = await fetchData(END_POINT.GetNewMiceTestimonial);

            if (result.success == true) {
                setData(result.data);
            }
        } catch (err) {
            console.log(err);
        }
    };
    const deleteHandler = async (id) => {
        try {
            const result = await fetchData(
                END_POINT.DeleteNewMiceTestimonialByid + id
            );
            if (result.success == true) {
                handler(result.data);
            }
        } catch (err) {
            console.log(err);
        }
    };
    return (
        <div>
            <Link
                style={{ textDecoration: "none" }}
                to={"../add-new-mice-testimonial"}
            >
                <button
                    className="btn btn-primary mt-4"
                // onClick={() => {
                //   Navigate("add-hotel-listing");
                // }}
                >
                    Add Testimonial&nbsp;<i class="fa-solid fa-plus"></i>
                </button>
            </Link>

            <div className="mt-4">
                <div
                    id="example_wrapper"
                    class="dataTables_wrapper no-footer table-overflow"
                >
                    <div id="example_filter" class="dataTables_filter mb-4">
                        <label>
                            Search:
                            <input
                                type="search"
                                class=""
                                placeholder=""
                                aria-controls="example"
                            />
                        </label>
                    </div>
                    <table
                        id="example"
                        class="table table-striped table-bordered dataTable no-footer"
                        style={{ width: "100%", whiteSpace: "nowrap" }}
                        role="grid"
                        aria-describedby="example_info"
                    >
                        <thead>
                            <tr role="row">
                                {["S.N", "Image", "Name", "Action"].map((item) => {
                                    return <TableHead title={item} />;
                                })}
                            </tr>
                        </thead>

                        <tbody>
                            {data.length > 0 ? (
                                data.map((item, index) => (
                                    <tr role="row" class="odd" key={index}>
                                        <td class="sorting_1"> {index + 1} </td>
                                        <td>
                                            <img
                                                src={BASE_URL_IMAGE + item.image}
                                                style={{ width: 100, height: 100 }}
                                            />
                                        </td>
                                        <td>
                                            {item?.name}
                                        </td>

                                        <td>
                                            <Link
                                                class="btn btn-primary m-1"
                                                to={{
                                                    pathname: "../add-new-mice-testimonial",
                                                    // search: "?sort=name",
                                                    // hash: "#the-hash",
                                                }}
                                                state={{ id: item._id }}
                                            >
                                                <i class="fa fa-edit"></i>
                                            </Link>

                                            <a
                                                // href="#"
                                                type="button"
                                                class="btn btn-danger m-1"
                                                onClick={() => {
                                                    Swal.fire({
                                                        title: "Are you sure?",
                                                        text: `You won't to delete ${item.name}!`,
                                                        icon: "warning",
                                                        showCancelButton: true,
                                                        confirmButtonColor: "#3085d6",
                                                        cancelButtonColor: "#d33",
                                                        confirmButtonText: "Yes, delete it!",
                                                    }).then((result) => {
                                                        if (result.isConfirmed) {
                                                            deleteHandler(item._id);

                                                            Swal.fire(
                                                                "Deleted!",
                                                                `${item.name} has been deleted.`,
                                                                "success"
                                                            );
                                                        }
                                                    });
                                                    //  handleDelete(item._id)
                                                }}
                                            >
                                                <i class="fa fa-trash"></i>
                                            </a>
                                        </td>

                                        {/* <td>{item.updatedAt}</td> */}
                                    </tr>
                                ))
                            ) : (
                                <p>Mice inspiration List Not Available</p>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default NewMiceTestimonial;

import React, { useState, useEffect } from "react";
import { END_POINT, METHODS, BASE_URL_IMAGE } from "../../../Helper/helper";
import {
    FileUpload,
    ImageUpload,
    VideoUpload,
    fetchData,
} from "../../../Helper/ApiCall";
import Select from "react-select";
import { Button, Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import {
    EditorState,
    convertToRaw,
    ContentState,
    convertFromRaw,
    convertFromHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
const AddNewMiceHighlights = () => {
    const navigate = useNavigate();
    let location = useLocation();
    const cityid = location?.state?.id;

    const [formData, setformData] = useState({
        title: "",
        link: "",
        video: "",
    });
    const handleChange = (e) => {
        setformData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const [video, setvideo] = useState("");
    const [banner, setbanner] = useState("");
    const [catData, setcatData] = useState([]);
    const [image, setimage] = useState("");

    useEffect(() => {
        // categoryHandler();
        if (cityid != undefined) {
            handler();
        }
    }, []);

    const handler = async () => {
        try {
            const result = await fetchData(
                END_POINT.GetNewMiceHighlightsByid + cityid
            );

            if (result.success == true) {
                setformData({
                    title: result.data.title,
                    link: result.data.link,
                    video: result.data.video,
                });
            }
        } catch (err) {
            console.log(err);
        }
    };
    const handleSubmit = async (uploadedvideo) => {
        var raw = JSON.stringify({
            title: formData.title,
            link: formData.link,
            video: uploadedvideo,
        });
        console.log("raw", raw);
        try {
            const finalEndpoint =
                cityid != undefined
                    ? END_POINT.UpdateNewMiceHighlights + cityid
                    : END_POINT.AddNewMiceHighlights;
            const result = await fetchData(finalEndpoint, METHODS.POST, raw);
            console.log("API response:", result);
            if (result.success == true) {
                Swal.fire({
                    title: "Confirmed",
                    text: result.message,
                    icon: "success",
                    showCancelButton: false,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes",
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate("../new-mice-highlights");
                    }
                });
            }
        } catch (err) {
            console.log(err);
        }
    };



    const AllFileUpload = async () => {
        Promise.all([
            await VideoUpload(video),
            await ImageUpload(banner),
            await ImageUpload(image),
        ])
            .then((values) => {
                console.log(values);
                const uploadedvideo = values[0].image;
                const uploadedImagebanner = values[1].image;
                const uploadedMoreImage = values[2].image;
                handleSubmit(uploadedvideo, uploadedImagebanner, uploadedMoreImage);
            })
            .catch((err) => {
                console.error(err);
            });
    };
    const AllFileUpdate = async () => {
        Promise.all([
            video != "" && (await VideoUpload(video)),
            banner != "" && (await ImageUpload(banner)),
            image != "" && (await ImageUpload(image)),
        ])
            .then((values) => {
                console.log("dsdsv", values);

                const uploadedvideo =
                    values[0] == false ? formData.video : values[0].image;
                const uploadedImagebanner =
                    values[1] == false ? formData.banner_image : values[1].image;
                const uploadedMoreImage =
                    values[2] == false ? formData.image : values[2].image;

                handleSubmit(uploadedvideo, uploadedImagebanner, uploadedMoreImage);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    return (
        <div
        //  onSubmit={AllFileUpload}
        >
            <div>
                <h3 className="mb-4">
                    <b>Add Mice Highlights</b>
                </h3>
            </div>

            <div className="row mb-4">
                <div className="col-lg-6 md-6 sm-12">
                    <div>
                        <span className="label-style">Title:</span> <br />
                        <input
                            type="text"
                            id="title"
                            name="title"
                            className="input-fields-style"
                            value={formData.title}
                            onChange={(e) => handleChange(e)}
                        />
                    </div>
                </div>
            </div>

            <div className="row mb-4">
                <div className="col-lg-6 md-6 sm-12">
                    <div>
                        <span className="label-style">Link:</span> <br />
                        <input
                            type="text"
                            id="link"
                            name="link"
                            className="input-fields-style"
                            value={formData.link}
                            onChange={(e) => handleChange(e)}
                        />
                    </div>
                </div>
            </div>


            <div className="row mb-4">
                <div className="col-lg-6 md-6 sm-12">
                    <div>
                        <span className="label-style">Video *</span> <br />
                        <input
                            type="file"
                            id="fileBox1"
                            name="fileBox1"
                            accept="video/*"
                            onChange={(e) => {
                                setvideo(e.target.files[0]);
                            }}
                        />
                    </div>
                    {formData.video != "" && (
                        <a
                            href={BASE_URL_IMAGE + formData.video}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            view video
                        </a>
                    )}
                </div>

            </div>




            <div className="d-flex justify-content-start">
                <Button
                    id="submit"
                    type="button"
                    className="btn btn-primary m-2"
                    // name="submit"
                    onClick={() => {
                        if (cityid != undefined) {
                            if (video != "") {
                                AllFileUpdate();
                            } else {
                                handleSubmit(formData.video);
                            }
                        } else {
                            AllFileUpload();
                        }
                    }}
                >
                    Submit
                </Button>

                <Button
                    className="btn btn-danger m-2"
                    type="cancel"
                    onClick={() => {
                        navigate("../new-mice-highlights");
                    }}
                >
                    Cancel
                </Button>
            </div>
        </div>
    );
};

export default AddNewMiceHighlights;
